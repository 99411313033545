<div class="rule-engine-header" style="height: 100vh; background-color: #f2f6f9">
  <div class="d-flex justify-content-between align-item-center" style="border-bottom: 1px solid lightgrey">
    <div class="d-flex align-item-center">
      <div class="logo-container cursor-pointer" [routerLink]="'/login'">
        <ng-container *ngIf="logo_sm != ''; else noLogo">
          <div>
            <img [src]="logo_sm" alt="" class="w-45px" loading="eager" />
          </div>
        </ng-container>

        <ng-template #noLogo>
          <div class="mt-10p">
            <img src="/assets/images/basic_icons/home_icon.svg" alt="" class="w-45px" loading="eager" />
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isCompanyLogo; else noBigLogo">
    <div class="d-flex justify-content-center" style="margin-top: 5vh">
      <!-- <img src="/assets/images/login/klearstack_logo.png" alt="" class="w-30p" /> -->
      <img [src]="logo_md" alt="" [ngStyle]="{ width: logo_md_width }" />
    </div>
  </ng-container>

  <ng-template #noBigLogo>
    <div class="d-flex justify-content-center" style="margin-top: 5vh">
      <img src="" alt="" class="w-30p" />
    </div>
  </ng-template>

  <div class="d-flex justify-content-center mt-3p">
    <h1 class="headline font-danger">Unauthorized: 401 Error</h1>
  </div>

  <div class="d-flex justify-content-center mt-2p ml-25p mr-25p">
    <h4 class="sub-content text-align-center">You are Unauthorized for this page. Please Login.</h4>
  </div>

  <div class="d-flex justify-content-center mt-3p">
    <!-- <button class="btn back-btn" [routerLink]="'/login'">Login</button> -->
    <button class="btn back-btn" (click)="navigateToLoginPage()">Login</button>
  </div>
</div>
