import { GlobalSpinnerService } from 'src/app/shared/services/global-spinner.service';
import { ErrorService } from './../services/error.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private globalSpinnerService: GlobalSpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log("error interceptor", error);

          if (error instanceof HttpErrorResponse) {
            if (error.status) {
              this.errorService.onErrorNavigation(error.status);
            }
          }
          return throwError(() => (error));
        }),
        (finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }))
      )

  }
}
